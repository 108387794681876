import { useEffect, useState, useRef } from 'react';
import logo from './logo.svg';
import './App.css';

import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup';

import DataTable from 'react-data-table-component';

//Moment react
import Moment from 'react-moment';

import axios from 'axios';

import ReactGA from 'react-ga';
const TRACKING_ID = "UA-214950732-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const useAnalyticsEventTracker = (category="GCM Download") => {
  const eventTracker = (action = "gcm download action", label = "Gcm download") => {
    ReactGA.event({category, action, label});
  }
  return eventTracker;
}

interface MessageFormProps {
  name: string;
  email: string; 
  message: string
}

function App() {

  const initialAppVersion: any = null;
  let [ appLatestVersion, setAppLatestVersion ] = useState(initialAppVersion);

  const gaEventTracker = useAnalyticsEventTracker();

  const initialAppRelease: any = [];
  let [ appReleases, setAppReleases ] = useState(initialAppRelease);

  let [ loading, setLoading ] = useState(false);

  const formRef = useRef<FormikProps< MessageFormProps >>(null);

  const columns = [ 
    {
        name: 'Version',
        selector: (row: any) => row.tag_name,
    },
    {
        name: 'Date de publication',
        selector: (row: any) => <Moment format="YYYY/MM/DD hh:mm:ss">
                                    {row.published_at}
                                </Moment>,
    },
    {
        name: "",
        cell: (row: any, rowIndex: number) => (<> { rowIndex === 0 ? <span style={{ backgroundColor: 'green' }} className="badge badge-success">Latest</span> : <></> }</> )
    },
    {
        name:"",
        selector: (row: any) => <a href={ row !== null ? row?.assets[0].browser_download_url : "" } onClick={()=>gaEventTracker(`download ${row.tag_name}`)}
                                    className="learn_more2" target="_blank" download={ row !== null ? row?.assets[0].name : "" }
                                    >Télécharger { row !== null ? row?.tag_name : "" }
                                </a>
    } 
  ];


  const conditionalRowStyles = [
    {
      when: (row: any) => row.draft || row.prerelease,
      style: {
        display: 'none' 
      },
    } 
  ];

  useEffect( () => {

    axios.get('https://api.github.com/repos/prokode/gcm-downloads/releases/latest').then(async function (response: any) {
      console.log(response);
      setAppLatestVersion(response.data);  
      console.log(appLatestVersion);
    })
      .catch(function (error: any) {
        console.log(error); 
    });

    axios.get('https://api.github.com/repos/prokode/gcm-downloads/releases').then(async function (response: any) {
      console.log(response);
      setAppReleases(response.data);  
      console.log(appReleases);
    })
      .catch(function (error: any) {
        console.log(error); 
    });

  }, []);

  return (
    <>  
    <header id="header_outer">
      <div className="container">
        <div className="header_section">
          <div className="logo"><a href="javascript:void(0)"><img src="assets/img/logo.JPG" alt="" /></a></div>
          <nav className="nav" id="nav">
            <ul className="toggle">
              <li><a href="#top_content">Accueil</a></li>
              <li><a href="#service">Services</a></li>
              <li><a href="#foncts">Fonctionnalités</a></li>
              <li><a href="#applimobile">Application mobile</a></li>
              <li><a href="#contact">Contacts</a></li>
            </ul>
            <ul className="">
              <li><a href="#top_content">Accueil</a></li>
              <li><a href="#service">Services</a></li>
              <li><a href="#foncts">Fonctionnalités</a></li>
              <li><a href="#applimobile">Application mobile</a></li>
              <li><a href="#contact">Contacts</a></li>
            </ul>
          </nav>
          <a className="res-nav_click animated wobble wow"  href="javascript:void(0)"><i className="fa-bars"></i></a> </div>
      </div>
    </header>

<section id="top_content" className="top_cont_outer">
  <div className="top_cont_inner">
    <div className="container">
      <div className="top_content">
        <div className="row">
          <div className="col-lg-5 col-sm-7">
            <div className="top_left_cont flipInY wow animated"> 
              <h2> GC MANAGER </h2>
              <p> GC MANAGER est un ensemble d'applications bureau et mobile permettant la gestion efficace de vos salles de jeux. Elle
              vous permet de digitaliser votre salle de jeux par la technologie des objets connectés. Vous commandez l'extension et l'allumage
              de vos postes conformément à vos différents tarifs.</p>
              <a href={ appLatestVersion !== null ? appLatestVersion?.assets[0].browser_download_url : "" }
                onClick={()=>gaEventTracker(`download ${appLatestVersion?.tag_name}`)}
                className="learn_more2" target="_blank" download={ appLatestVersion !== null ? appLatestVersion?.assets[0].name : "" } >Télécharger { appLatestVersion !== null ? appLatestVersion?.tag_name : "" }
              </a>
            </div>
          </div>
          <div className="col-lg-7 col-sm-5"> </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section  id="service">
  <div className="container">
    <h2>Services</h2>
    <div className="service_area">
      <div className="row">
        <div className="col-lg-4">
          <div className="service_block">
            <div className="service_icon delay-03s animated wow  zoomIn"> <span><i className="fa-desktop"></i></span> </div>
            <h3 className="animated fadeInUp wow">Flexibilité</h3>
            <p className="animated fadeInDown wow">
              Gérer les postes de votre salle plus facilement
            </p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="service_block">
            <div className="service_icon icon2  delay-03s animated wow zoomIn"> <span><i className="fa-list-alt"></i></span> </div>
            <h3 className="animated fadeInUp wow">Rapports</h3>
            <p className="animated fadeInDown wow">
              Obtenez des rapports sur l'activité de vos salles de jeux, même en étant pas présent dans vos salles de jeux
            </p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="service_block">
            <div className="service_icon icon3  delay-03s animated wow zoomIn"> <span><i className="fa-lock"></i></span> </div>
            <h3 className="animated fadeInUp wow">Sécurité</h3>
            <p className="animated fadeInDown wow">Logiciels sécurisés et avec différents types de compte de connexion.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="foncts">
<div className="top_cont_latest">
  <div className="container">
    <h2>
      Fonctionnalités
    </h2>
    <div className="work_section">
      <div className="row">
        <div className="col-lg-6 col-sm-6 wow fadeInLeft delay-05s">
          <div className="service-list">
            <div className="service-list-col1"> <i className="icon-key"></i> </div>
            <div className="service-list-col2">
              <h3>Activation</h3>
              <p>Obtenez un code d'activation et un code poste en nous contactant.</p>
            </div>
          </div>
          <div className="service-list">
            <div className="service-list-col1"> <i className="icon-cog"></i> </div>
            <div className="service-list-col2">
              <h3>Configuration</h3>
              <p>
                Configurer les consoles, les postes et vos différents tarifs de votre salle de jeux. <br />
                Gérer les agents qui gèrent vos salles de jeux.
              </p>
            </div>
          </div>
          <div className="service-list">
            <div className="service-list-col1"> <i className="icon-database"></i> </div>
            <div className="service-list-col2">
              <h3>Sauvegarde</h3>
              <p>Enrégistrer vos configurations et vos ventes sur notre Backend et visualizer les sur votre compte en ligne.</p>
            </div>
          </div>
          <div className="service-list">
            <div className="service-list-col1"> <i className="icon-mobile"></i> </div>
            <div className="service-list-col2">
              <h3>Application mobile</h3>
              <p>Retrouvez les fonctionnalités sur notre application mobile fournit gratuitement.</p>
            </div>
          </div>
          <div className="work_bottom">
            <span>Télécharger la dernière version { appLatestVersion !== null ? appLatestVersion?.tag_name : "" }</span>
            <a target="_blank" href={ appLatestVersion !== null ? appLatestVersion?.assets[0].browser_download_url : "" }
              onClick={()=>gaEventTracker(`download ${appLatestVersion?.tag_name}`)}
              className="learn_more2" download={ appLatestVersion !== null ? appLatestVersion?.assets[0].name : "" }>Télécharger { appLatestVersion !== null ? appLatestVersion?.tag_name : "" }</a>
          </div>
        </div>
        <figure className="col-lg-6 col-sm-6  text-right wow fadeInUp delay-02s"> </figure>
      </div>
    </div>
  </div>
  </div>
</section>


<section className="module mb-30" id="applimobile">
  <div className="container ">
    <div className="row">
      <div className="col-sm-6 col-sm-offset-3">
        <h2>
          Application mobile
        </h2>
        <p className="animated fadeInDown wow">
          L'application mobile est offerte gratuitement et vous permet de gérer plus facilement toutes les activités de vente dans votre salle de jeux.
        </p>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-6 col-md-3 col-lg-3">
        <div className="service-list" ></div>
        <div className="service-list">
          <div className="service-list-col1"> <i className="icon-mobile"></i> </div>
          <div className="service-list-col2">
            <h3>Vente</h3>
            <p>Faite toutes vos ventes à partir de votre téléphone mobile.</p>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-6 hidden-xs hidden-sm">
        <p className="text-center"><img src="assets/img/App1.png" alt="Feature Image" /></p>
      </div>

      <div className="col-sm-6 col-md-3 col-lg-3">
        <div className="service-list" ></div>
        
        <div className="service-list">
          <div className="service-list-col1"> <i className="icon-key"></i> </div>
          <div className="service-list-col2">
            <h3>Sécurité</h3>
            <p>Connectez vous sur l'application mobile avec les mêmes identifiants que ce ceux utilisés sur l'application de votre machine bureau.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

{   appLatestVersion !== null && 
    <section className="module mb-30" id="applimobile">
      <div className="container ">
        <div className="row">
          <div className="col-sm-6 col-sm-offset-3">
            <h2>
              Versions GCM disponibles
            </h2>
            <p className="animated fadeInDown wow">
              Nous vous conseillons de télécharger la dernière version(Latest), avec les dernières mises à jour.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="table-responsive">
                                        <DataTable
                                            responsive={true}
                                            className="table table-striped table-bordered"
                                            columns={columns}
                                            data={appReleases}
                                            progressPending={loading}
                                            conditionalRowStyles={conditionalRowStyles}
                                            pagination
                                        />
                  </div>
          </div>
        </div>
      </div>
    </section>
}

<br />
<br />

<footer className="footer_section" id="contact">
  <div className="container">
    <section className="main-section contact" >
      <div className="contact_section">
        <h2>Contacts</h2>
        <div className="row">
          <div className="col-lg-4">
            <div className="contact_block">
              <div className="contact_block_icon rollIn animated wow"><span><i className="fa-home"></i></span></div>
              <span> Derrière l'établissement ITEM à Bè Lomé, un peu près avant l'église catholique Maria auxiliadora de Gbenyedzi. </span> </div>
          </div>
          <div className="col-lg-4">
            <div className="contact_block">
              <div className="contact_block_icon icon2 rollIn animated wow"><span><i className="fa-phone"></i></span></div>
              <span> 90 - 06 - 53 - 16 / 91 - 64 - 69 - 85 </span> </div>
          </div>
          <div className="col-lg-4">
            <div className="contact_block">
              <div className="contact_block_icon icon3 rollIn animated wow"><span><i className="fa-pencil"></i></span></div>
              <span> <a href="mailto:tamekloes@gmail.com">
                tamekloes@gmail.com
              </a> </span> </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 wow fadeInLeft">
          <div className="contact-info-box address clearfix">
            <h3>N'hésitez pas à nous contacter ! Pour tous vos besoins, remplissez juste ce formulaire.</h3>
          </div> 
        </div>
        <div className="col-lg-6 wow fadeInUp delay-06s">

        <Formik
            innerRef={formRef}
            initialValues={ 
                {
                name: '',
                email: '',
                message: ''
            }}
            validationSchema={
                yup.object().shape({
                    name: yup
                        .string() 
                        .required('Ce champ est obligatoire'),
                    email: yup
                        .string()
                        .email("Email invalide"),
                    message: yup
                        .string()
                        .required('Ce champ est obligatoire'), 
                })
            }
            onSubmit={async (
                values 
              ) => {
                   
                  setLoading(true);
                  axios.post('https://api.dotcomteam.net/web/send_mail.php', values).then(async function (response: any) {
                    console.log(response);
                    if (response.data.message === 'error') {
                      alert("Une erreur s'est produite réesseyez s'il vous plaît.");
                    } else if (response.data.message === 'success') {
                      alert("Message envoyé avec succès, merci.");
                      if (formRef.current !== null) {
                        formRef.current.values.name = "";
                        formRef.current.values.email = "";
                        formRef.current.values.message = ""; 
                      }
                      
                    } 
                    setLoading(false);
                  })
                  .catch(function (error: any) {
                      console.log(error); 
                      alert("Une erreur s'est produite réesseyez s'il vous plaît.");
                      setLoading(false);
                  });
                    
              }}
          >
            {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                        <Form>
                            <div className="form-body form" >

                                              <div className="form-group">
                                                    <input type="text"
                                                        className={`input-text animated wow flipInY delay-02s form-control ${ errors.name && touched.name ? "is-invalid":""}`}
                                                        id="name" 
                                                        name="name" 
                                                        onChange={handleChange('name')}
                                                        onBlur={handleBlur('name')}
                                                        value={values.name}
                                                        placeholder="Nom" />
                                                    { errors.name && touched.name && errors.name && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.name && touched.name && errors.name }
                                                    </div> }
                                              </div>


                                              <div className="form-group">
                                                    <input type="email"
                                                        className={`input-text animated wow flipInY delay-04s form-control ${ errors.email && touched.email ? "is-invalid":""}`}
                                                        id="email" 
                                                        name="email" 
                                                        onChange={handleChange('email')}
                                                        onBlur={handleBlur('email')}
                                                        value={values.email}
                                                        placeholder="Email" />
                                                    { errors.email && touched.email && errors.email && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.email && touched.email && errors.email }
                                                    </div> }
                                              </div>


                                              <div className="form-group">
                                                    <Field as="textarea" rows={5}
                                                        className={`input-text animated wow flipInY delay-0s form-control ${ errors.message && touched.message ? "is-invalid":""}`}
                                                        id="message" 
                                                        name="message" 
                                                        onChange={handleChange('message')}
                                                        onBlur={handleBlur('message')}
                                                        value={values.message}
                                                        placeholder="Message" />
                                                    { errors.message && touched.message && errors.message && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.message && touched.message && errors.message }
                                                    </div> }
                                              </div>

                                              <button disabled={!dirty && !isValid } 
                                              className="input-btn animated wow flipInY delay-08s" 
                                              type="submit">
                                                Envoyer
                                                {
                                                    loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                              </button>

                              {/* <form  method="POST" action="send_mail.php"  className="form">
                                <input className="input-text animated wow flipInY delay-02s form-control" type="text" name="name" value="Votre nom *"
                                onFocus="if(this.value==this.defaultValue)this.value='';" onBlur="if(this.value=='')this.value=this.defaultValue;" required>
                                <input className="input-text animated wow flipInY delay-04s form-control" type="text" name="email" value="Votre E-mail *" onFocus="if(this.value==this.defaultValue)this.value='';" onBlur="if(this.value=='')this.value=this.defaultValue;">
                                <textarea name="message" className="input-text text-area animated wow flipInY delay-06s form-control" cols="0" rows="0" required
                                          onFocus="if(this.value==this.defaultValue)this.value='';" onBlur="if(this.value=='')this.value=this.defaultValue;">Votre message*</textarea>
                                
                              </form> */}

                            </div>
                        </Form>
                    )}
          </Formik>
        </div>
      </div>
    </section>
  </div>
  <div className="container">
    <div className="footer_bottom"> <span>Copyright © 2022 | <a href="https://dotcomteam.net/">GC Manager</a> by DOTCOM / TKSM </span> </div>
  </div>
</footer>
    </>
  );
}

export default App;
